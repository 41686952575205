export function getStatusIcon(state) {
  switch (state) {
    case 'Доставляется':
    case 'Отправлен в ресторан':
    case 'Отправляется в ресторан':
      return 'icon-status__way';

    case 'Завершен':
    case 'Доставлен':
      return 'icon-status__success';

    case 'Ошибка':
    case 'Отменен':
      return 'icon-status__error';

    default:
      return 'icon-status__cooking';
  }
}

export function parseComment(comment) {
  if (!comment.includes(' ') && comment.length >= 60) {
    return comment.slice(0, 25) + '...';
  }
  return comment.length >= 60 ? comment.slice(0, 60) + '...' : comment;
}

export function getPaymentType(type) {
  switch (type) {
    case 'cash':
      return 'Наличными при получении';
    case 'cashless':
      return 'Картой при получении';
    case 'card2card':
      return 'С карты на карту';
    case 'yandex':
      return 'Онлайн';
    default:
      return 'Наличными при получении';
  }
}

export function mapOptions(
  initialData,
  keyField,
  valueField,
  optionalField = null,
) {
  const optionValue = (el) => {
    if (optionalField) {
      return `${el[optionalField]} - ${el[valueField]}`;
    }
    return el[valueField];
  };
  return initialData.map((el) => ({
    key: el[keyField],
    value: optionValue(el),
  }));
}

export const getMaxLoyaltyValue = ({
  totalCost,
  maxPersentPayedByLoyalty,
  minDeliveryPrice,
  isDelivery,
}) => {
  const discountAmountByLoyaltyPercent =
    (totalCost * maxPersentPayedByLoyalty) / 100;

  if (
    isDelivery &&
    totalCost - discountAmountByLoyaltyPercent < minDeliveryPrice
  ) {
    return totalCost - minDeliveryPrice;
  }
  return Math.floor(discountAmountByLoyaltyPercent);
};

export const getCostAfterAmountDiscount = ({ totalCost, discountAmount }) => {
  if (discountAmount === null) {
    return null;
  } else if (totalCost - discountAmount < 0) {
    return 0;
  }
  return totalCost - discountAmount;
};

export const getTotalAmountAfterAllDiscounts = ({
  discount,
  costAfterDiscount,
  costAfterAmountDiscount,
  totalCost,
  positiveNumberFromLimit,
  deliveryPrice,
  discountAmount,
  minPriceForFreeDelivery,
}) => {
  function getRawPrice() {
    if (discount !== null) {
      return costAfterDiscount;
    }
    if (costAfterAmountDiscount !== null) {
      if (minPriceForFreeDelivery < totalCost - discountAmount) {
        return totalCost - discountAmount;
      }
      return totalCost + deliveryPrice - discountAmount;
    }
    if (minPriceForFreeDelivery < totalCost - positiveNumberFromLimit) {
      return totalCost - positiveNumberFromLimit;
    }
    return totalCost - positiveNumberFromLimit + deliveryPrice;
  }

  const rawPrice = Math.trunc(getRawPrice());

  if (rawPrice > 0) {
    return rawPrice;
  }
  return 0;
};
